<template>
<div class="list-ranking-cate" v-if="listCate.length">
  <!-- block title ranking category -->
  <div class="block-title block-title-ranking">
    <h2 class="title-green">RANKING</h2>
    <span class="sub-green">カテゴリ別ランキング</span>
  </div>

  <!-- list ranking on pc -->
  <div class="list">
    <div class="list-cate">
      <p>CATEGORY</p>
      <ul>
        <li @click="filterCate(cate.id, checkValidCategory(cate))"
            :class="[{'active':index === tabIndex}, {'noData': !cate.category}]"
            v-for="(cate,index) in listCate" :key="index" v-html="cate.category ? cate.category.title : '...' ">
        </li>
      </ul>
    </div>

    <div class="list-rank-order">

      <!-- rank list -->
      <div class="item-ranking" v-for="(item) in listCate[tabIndex].advertiseRankings" :key="item.rank">
        <div class="bg-rank" :class="`bg-rank-${item.rank}`"
             v-if="item.advertise_id">
          <div class="ranking">
            <img :src="require('../../assets/images/icon/crown'+ item.rank + '.svg')" alt="">
            {{item.rank}}{{ displaySuffixes(item.rank) }}
          </div>
        </div>
        <div @click="handleRedirectAdDetail(item.advertise_id || 1)"
                     :class="{'pointer-none': !checkValidAdvertise(item)}">

          <template v-if="checkValidAdvertise(item)">
            <figure>
              <img :src="(item.advertise.file ? item.advertise.file.path : '') | imageEmpty" alt="">
            </figure>

            <h4 class="line-clamp-02">{{ item.advertise.title }}</h4>

            <span class="description line-clamp-01" v-html="item.advertise.list_description"/>

            <!-- rank1 buy back -->
            <template>
              <template v-if="item.advertise.is_campaign === 1
                              && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
                <template v-if="item.advertise.point_type === 2">
                  <p class="campaign_text">通常 {{item.advertise.normal_pointback_rate | numberWithCommas(',', '%P')}}</p>
                </template>
                <template v-else >
                  <p class="campaign_text">通常 {{item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')}}</p>
                </template>
              </template>
            </template>

            <!--  item reward-->
            <template>
              <template v-if="item.advertise.is_campaign === 1
                               && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
                <template v-if="item.advertise.point_type === 2">
                  <strong>{{item.advertise.campaign_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
                </template>
                <template v-else >
                  <strong>{{item.advertise.campaign_pointback_amount | numberWithCommas(',', 'P')}}</strong>
                </template>
              </template>
              <template v-else>
                <template v-if="item.advertise.point_type === 2">
                  <strong>{{item.advertise.normal_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
                </template>
                <template v-else >
                  <strong>{{item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')}}</strong>
                </template>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>

  <!-- list ranking on sp -->
  <div class="wrap-slide sp" v-if="listCate.length">
    <!-- list tab on top slide category ranking -->
    <div class="list-cate-sp"
         ref="listCate">
      <ul>
        <li @click="scrollPage(index,checkValidCategory(item))"
            v-for="(item, index) in listCate"
            :key="index"
            :class="[{'active': index === tabIndex}, {'noData': !item.category}]">
          {{ item.category ? item.category.title : '...' }}
        </li>
      </ul>
    </div>

    <!-- slide category ranking -->
    <VueSlickCarousel v-bind="settingSlide"
                      @beforeChange="beforeChange"
                      ref="carousel"
                      class="slide-ranking">
      <div v-for="(item, index) in listCate"
           :key="index"
           class="list-rank-order">
        <!-- rank list -->
        <div @click="handleRedirectAdDetail(item.advertise_id || 1)"
                     class="item-ranking"
                     :class="{ 'pointer-none' : !checkValidAdvertise(item) }"
                     v-for="(item) in listCate[tabIndex].advertiseRankings" :key="item.rank">
          <div v-if="item.advertise_id"
               class="bg-rank"
               :class="`bg-rank-${item.rank}`">
            <div class="ranking">
              <img :src="require('../../assets/images/icon/crown'+ item.rank + '.svg')" alt="">
              {{item.rank}}{{ displaySuffixes(item.rank) }}
            </div>
          </div>

          <figure v-if="checkValidAdvertise(item)">
            <img :src="(item.advertise.file ? item.advertise.file.path : '') | imageEmpty" alt="">
          </figure>

          <div :class="{'hidden-content': !checkValidAdvertise(item)}" class="content-ranking">

            <template v-if="checkValidAdvertise(item)">

              <div>
                <h4 class="line-clamp-02">{{ item.advertise.title }}</h4>
                <span class="description line-clamp-01" v-html="item.advertise.list_description"/>

                <!-- item buy back -->
                <template>
                  <template v-if="item.advertise.is_campaign === 1
                                  && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
                    <template v-if="item.advertise.point_type === 2">
                      <p class="campaign_text">通常 {{item.advertise.normal_pointback_rate | numberWithCommas(',', '%P')}}</p>
                    </template>
                    <template v-else >
                      <p class="campaign_text">通常 {{item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')}}</p>
                    </template>
                  </template>
                </template>

                <!--  item reward-->
                <template>
                  <template v-if="item.advertise.is_campaign === 1
                                  && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
                    <template v-if="item.advertise.point_type === 2">
                      <strong>{{item.advertise.campaign_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
                    </template>
                    <template v-else >
                      <strong>{{item.advertise.campaign_pointback_amount | numberWithCommas(',', 'P')}}</strong>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="item.advertise.point_type === 2">
                      <strong>{{item.advertise.normal_pointback_rate | numberWithCommas(',')}}<span>%</span>P</strong>
                    </template>
                    <template v-else >
                      <strong>{{item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')}}</strong>
                    </template>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- create link between dots carousel and tab -->
      <template #customPaging="page">
        <button class="custom-dot" @click.once="changeTab(page)" :disabled="!listCate[page].category"></button>
      </template>
    </VueSlickCarousel>
  </div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Common from '@/mixins/common.mixin'

export default {
  name: 'ListRanking',

  components: {
    VueSlickCarousel
  },

  mixins: [Common],

  data () {
    return {
      settingSlide: {
        arrows: true,
        dots: true,
        autoplay: false,
        speed: 500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        swipeToSlide: true
      },
      activeTab: '',
      tabIndex: 0
    }
  },

  mounted () {
    this.activeTab = (this.listCate.length > 0 && this.listCate[0].category)
      ? this.listCate[0].category.id : ''
  },

  props: {
    listCate: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    displaySuffixes (str) {
      switch (str) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
      }
    },

    scrollPage (page, name) {
      if (this.listCate[page].category) {
        // active tab
        this.activeTab = name
        this.tabIndex = page

        // scroll tab to left horizontal
        this.scrollTab(this.tabIndex)

        // scroll slide flow tab
        this.$refs.carousel.goTo(page)
      }
    },

    changeTab (tab) {
      if (this.listCate[tab].category) {
        // active tab when click dots
        this.activeTab = this.listCate[tab].category.id
        this.tabIndex = tab

        // scroll tab
        this.scrollTab(this.tabIndex)
      }
    },

    scrollTab (tabIndex) {
      const listItemTab = this.$refs.listCate.children[0].children
      const tabOffsetLeft = this.$refs.listCate.offsetLeft
      let leftSpacing = listItemTab[tabIndex].offsetLeft
      if (leftSpacing >= tabOffsetLeft) {
        leftSpacing = leftSpacing - tabOffsetLeft
      }
      this.$refs.listCate.scroll({
        left: leftSpacing,
        top: 0,
        behavior: 'smooth'
      })
    },

    beforeChange (oldSlideIndex, newSlideIndex) {
      if (this.listCate[newSlideIndex].category) {
        // active tab when click arrow
        this.activeTab = this.listCate[newSlideIndex].category.id
        this.tabIndex = newSlideIndex

        // scroll tab to horizontal
        this.scrollTab(this.tabIndex)
      }
    },

    filterCate (id, name) {
      const index = this.listCate.findIndex((p) => p.id === id)
      if (index > -1 && this.listCate[index].category) {
        this.tabIndex = index
        this.activeTab = name
      }
    },

    checkValidCategory (cate) {
      if (cate.category) return cate.category.id
      return ''
    },

    checkValidAdvertise (adv) {
      if (adv.advertise) return true
      return false
    },
    handleRedirectAdDetail (id) {
      if (this.checkWebview()) {
        const url = process.env.VUE_APP_USER_DOMAIN + 'advertising-detail/' + id
        this.triggerEventApp(
          'OPEN_WEBVIEW',
          { url: url }
        )
      } else {
        this.$router.push({ name: 'AdvertisingDetail', params: { id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-ranking-cate {
  padding: 32px 0 44px;
  border: 6px solid #E7E8DE;
  border-radius: 6px;
  width: 100%;
  background: #FAFAFA;
  @media #{$info-phone} {
    border: 3px solid #E7E8DE;
    padding: 29px 0 0;
  }
  .block-title-ranking {
    @media #{$info-phone} {
      padding: 0 15px;
    }
  }
  .list {
    transform: translateX(-30px);
    display: flex;
    align-items: flex-start;
    @media #{$info-tablet-vertical} {
      transform: translateX(0);
      flex-direction: column;
    }
    @media #{$info-phone} {
      display: none;
    }
    .list-cate {
      font-family: $font-family-title-R;
      max-width: 216px;
      width: 100%;
      flex-shrink: 0;
      background: #BDBFAE;
      border-radius: 6px;
      padding: 24px 24px 19px;
      @include font-size(17.2px);
      margin-right: 10px;
      @media #{$info-tablet-horizontal} {
        max-width: 190px;
        padding: 20px 15px;
      }
      @media #{$info-tablet-vertical} {
        max-width: 100%;
        margin-right: 0;
        transform: translateX(-20px);
      }
      p {
        font-family: $font-family-title-B;
        color: #ffffff;
        padding-bottom: 16px;
        margin-bottom: 27px;
        position: relative;
        @include font-size(20px);
        &:after {
          content: '';
          position: absolute;
          width: 40px;
          height: 6px;
          background: #6F8784;
          left: 0;
          bottom: 0;
        }
      }
      ul {
        li {
          margin-bottom: 5px;
          cursor: pointer;
          word-break: break-all;
          &.active {
            font-family: $font-family-title-B;
            color: #ffffff;
          }
          &:hover {
            color: #FFFFFF;
          }
          &.noData {
            cursor: default;
          }
        }
      }
    }
  }
  .list-rank-order {
    display: flex;
    align-items: flex-start;
    width: 100%;
    @media #{$info-tablet-vertical} {
      padding: 0 10px;
    }
    .item-ranking {
      max-width: 168px;
      width: 100%;
      position: relative;
      z-index: 1;
      padding-top: 60px;
      margin-left: 38px;
      margin-top: 25px;
      &:hover {
        cursor: pointer;
      }
      @media #{$info-tablet-vertical} {
        margin-left: 12px;
      }
      @media #{$info-phone} {
        max-width: 100%;
        display: flex;
        padding-top: 35px;
        margin-left: 0;
        margin-top: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid #E7E8DE;
      }
      &:nth-child(2) {
        margin-top: 50px;
        @media #{$info-phone} {
          margin-top: 18px;
        }
      }
      &:nth-child(3) {
        margin-top: 75px;
        @media #{$info-phone} {
          margin-top: 18px;
        }
      }
      &:first-child {
        @media #{$info-tablet-vertical} {
          margin-left: 0;
        }
      }
      a {
        color: #282828;
        p {
          @include font-size(12px)
        }
      }
      .content-ranking {
        margin-left: 20px;
        flex: 1;
      }
      .bg-rank {
        @media #{$info-tablet-horizontal} {
          max-width: 124px;
          max-height: 124px;
        }
        @media #{$info-tablet-vertical} {
          max-width: 110px;
          max-height: 110px;
        }
        @media #{$info-phone} {
          position: absolute;
          left: 22px;
          top: 38px;
        }

        .ranking {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 24px;
          font-family: $font-label;
          @include font-size(32px);
          font-style: italic;
          color: #6F8784;
          margin-top: -25px;
          @media #{$info-phone} {
            transform: translateX(98px);
            margin-top: -36px;
          }
          img {
            margin-right: 4px;
          }
        }
      }

      figure {
        width: 163px;
        height: 163px;
        margin-bottom: 16px;
        @media #{$info-tablet-horizontal} {
          width: 100%;
          height: 150px;
        }
        @media #{$info-phone} {
          width: 100px;
          height: 100px;
        }
        img {
          width: 100%;
          height: 100%;
          border: 1px solid #BDBFAE;
        }
      }
      h4 {
        font-family: $font-family-title-B;
        @include font-size(16px);
        color: #7C934C;
        word-break: break-all;
        @media #{$info-phone} {
          margin: 10px 0 5px;
          font-size: 16px;
          line-height: 1.4;
        }
      }
      span.description {
        @include font-size(12px);
        margin-bottom: 20px;
        color: #282828;
        word-break: break-word;
        @media #{$info-phone} {
          font-size: 13px;
          margin-bottom: 11px;
          line-height: 1;
        }
      }
      p.campaign_text {
        color: #282828;
        @include font-size(12px);
        white-space: nowrap;
        @media #{$info-phone} {
          text-align: right;
        }
      }
      strong {
        font-family: $font-label;
        color: #6F8784;
        @include font-size(36px);
        font-style: italic;
        font-weight: normal;
        line-height: 1;
        @media #{$info-phone} {
          font-size: 32px;
          display: block;
          text-align: right;
        }
        span {
          @include font-size(21px);
          @media #{$info-phone} {
            font-size: 18px;
          }
        }
      }
      .hidden-content {
        opacity: 0;
        visibility: hidden;

        @media #{$info-phone} {
          margin-bottom: 80px;
        }
      }
    }
  }
  .wrap-slide {
    .list-cate-sp {
      overflow-x: auto;
      border-bottom: 3px solid #BDBFAE;
      ul {
        display: flex;
        white-space: nowrap;
        li {
          padding: 6px 12px;
          background: #E7E8DE;
          margin-left: 3px;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          cursor: pointer;
          &.active {
            background: #BDBFAE;
            color: #ffffff;
            font-family: $font-family-title-B;

          }
          &.noData {
            cursor: default;
          }
        }
      }
    }
    .slide-ranking {
      padding-bottom: 52px;
      .none-pointer {
        pointer-events: none;
      }
    }
  }
}

.pointer-none {
  pointer-events: none;
  border-bottom: none !important;
}
</style>
